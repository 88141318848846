var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BottomBar", {
    staticClass: "pick-team-bottom-bar",
    attrs: { columns: false },
    scopedSlots: _vm._u([
      {
        key: "right",
        fn: function () {
          return [
            _c("BreakoutControl"),
            _vm.isMainPanelBtnVisible ? _c("MainDrawerToggle") : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }