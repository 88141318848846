<template>
  <BottomBar :columns="false" class="pick-team-bottom-bar">
    <template #right>
      <BreakoutControl />
      <MainDrawerToggle v-if="isMainPanelBtnVisible" />
    </template>
  </BottomBar>
</template>

<script>
import { mapGetters } from "vuex"
import Vue from "vue"
import BottomBar from "./BottomBar.vue"
import Role from "@shared/enums/Role"
import MainDrawerToggle from "@/components/drawers/main/MainDrawerToggle.vue"
import BreakoutControl from "@/components/GroupTeams/Common/BreakoutControl.vue"

export default Vue.extend({
  name: "PickTeamsBottomBar",
  components: {
    BottomBar,
    MainDrawerToggle,
    BreakoutControl
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isMainPanelBtnVisible() {
      return this.user?.role === Role.HOST
    }
  }
})
</script>

<style lang="scss">
.pick-team-bottom-bar {
  background: #292932;
  width: 245px;
  margin-left: auto;
  align-items: center;
  border-top-left-radius: 20px;
  z-index: 101;

  &--without-breakouts {
    width: 60px;
    border-radius: inherit;
  }
}
</style>
