<template>
  <div class="bottom-bar">
    <slot name="left" />
    <slot name="center" />
    <slot name="right" />
  </div>
</template>

<script>
export default {
  name: "BottomBar",
  props: {
    columns: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.bottom-bar {
  min-height: 60px;
  display: flex;

  & > div {
    flex: 1;
  }
}
</style>
